import React, { useContext } from "react";
import ui from "structure/MassiveInvoiceDownload/styles.module.scss";
import { ContextMassiveInvoiceDownload } from "..";

/**
 * Calculate the percentage of download advanced
 * @param {number} current
 * @param {number} total
 */
function calculatePercentage(current, total) {
  if (total === 0) return 0; // To avoid division by zero
  return (current / total) * 100;
}

export default function DownloadingScreen() {
  const state = useContext(ContextMassiveInvoiceDownload);

  if (state.state.state === "none") return <></>;

  return (
    <>
      <p className="text-center my-3">
        Por favor espere a que el progreso llegue al 100% para que los archivos
        se descarguen en su dispositivo
      </p>

      <span className={ui.content}>Descargando facturas</span>
      {typeof state.state.invoicesDownloaded === "number" && (
        <span className={ui.content}>
          ({state.state.invoicesDownloaded} de {state.state.invoicesFound})
        </span>
      )}

      <div className="progress mt-3">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width:
              typeof state.state.invoicesDownloaded === "number"
                ? `${calculatePercentage(
                    state.state.invoicesDownloaded,
                    state.state.invoicesFound
                  )}%`
                : "0%",
          }}
        ></div>
      </div>
    </>
  );
}
