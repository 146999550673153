import { yupResolver } from "@hookform/resolvers/yup";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form";
import { schemaAdd } from "Schemas/Support/contracts";
import TagsSupport from "../Tags";
import { Label } from "structure/Document";
import Customer from "components/general/Customer";
import {
  addSupportContract,
  getSupportContract,
  updateSupportContract,
} from "helpers/Apis/support";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { GetCustomer } from "helpers/Apis/Directory";
import { dateAtCeroHours, dateToDbFormat, timeSpent } from "helpers/dates";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { add } from "date-fns";
import { RangeDate } from "components/general/RangeDate";
registerLocale("es", es);

const ContextFormSupportContract = createContext(undefined);

/**
 * Render the form for the contracts
 * @param {import("./types").FormContractProps} props - Props
 * @returns {JSX.Element}
 */
export default function FormContract(props) {
  const [state, setState] = useState({
    status: "none",
    contract: undefined,
    customer: undefined,
  });

  const idForm = useRef(`${window.crypto.randomUUID()}`);

  const form = useForm({
    resolver: yupResolver(schemaAdd),
    criteriaMode: "all",
  });

  const handleSubmit = async (dto) => {
    setState((current) => ({
      ...current,
      status: "loading",
    }));

    const expiration =
      dto.expiration === null ? null : dateToDbFormat(dto.expiration);

    const createOperation =
      typeof props?.idSupportContract === "number" ? false : true;

    let wasPerformedWithSuccess = false;

    if (createOperation) {
      wasPerformedWithSuccess = await addSupportContract({
        createdBy: "? ",
        customer: dto.customer,
        hours: dto.hours,
        tag: dto.tag,
        expiration,
      });
    } else {
      wasPerformedWithSuccess = await updateSupportContract({
        expiration,
        hours: dto.hours,
        tag: `${dto.tag}`,
        id: props.idSupportContract,
        updatedBy: "???",
      });
    }

    setState((current) => ({
      ...current,
      status: "none",
    }));

    if (wasPerformedWithSuccess && props?.onSuccessSubmited !== undefined)
      props?.onSuccessSubmited();
  };

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        status: "loading",
      }));

      const contract = await getSupportContract(props?.idSupportContract);

      if (contract !== null) {
        const user = await GetCustomer(contract.idCustomer);

        setState((current) => ({
          ...current,
          contract,
          customer: user,
          status: "none",
        }));

        form.setValue("tag", contract.agreementTag);
        form.setValue("hours", contract.hoursRequested);
        form.setValue("customer", contract.idCustomer);
      }

      setState((current) => ({
        ...current,
        status: "none",
      }));
    })();
  }, []);

  return (
    <ContextFormSupportContract.Provider
      value={{ idSupportContract: props?.idSupportContract, idForm, ...state }}
    >
      <FormProvider {...form}>
        <form
          {...props}
          id={idForm.current}
          onSubmit={form.handleSubmit(handleSubmit, console.log)}
          noValidate
        >
          {props.children}
        </form>
      </FormProvider>
    </ContextFormSupportContract.Provider>
  );
}

export function Submit() {
  const hook = useContext(ContextFormSupportContract);

  if (hook.status !== "none")
    return <Spinner text={"Guardando"} hidden={false} />;

  if (typeof hook.idSupportContract !== "number")
    return (
      <ButtonV2 className="w-100" type="submit" form={hook.idForm.current}>
        <span>Crear contrato</span>
      </ButtonV2>
    );

  return (
    <ButtonV2 className="w-100" type="submit" form={hook.idForm.current}>
      <span>Guardar contrato</span>
    </ButtonV2>
  );
}

export function Tag() {
  const hook = useFormContext();

  const state = useContext(ContextFormSupportContract);

  if (state.status !== "none") return <></>;

  return (
    <Controller
      control={hook.control}
      name="tag"
      defaultValue={state.contract?.agreementTag}
      render={({ onChange, value }) => (
        <div>
          <TagsSupport
            value={value}
            onChange={(data) => onChange(data.value)}
          />
          <p className="text-danger font-weight-bold m-0">
            {hook.errors?.tag?.message}
          </p>
        </div>
      )}
    />
  );
}

export function CustomerField() {
  const hook = useFormContext();

  return (
    <Controller
      name="customer"
      control={hook.control}
      render={({ onChange, value }) => (
        <div>
          <Label>Cliente</Label>
          <Customer
            defaultValue={value}
            type="cliente"
            onChange={(customer) => onChange(customer)}
            props={{
              menuPortalTarget: undefined,
            }}
          />
          <p className="text-danger font-weight-bold m-0">
            {hook.errors?.customer?.message}
          </p>
        </div>
      )}
    />
  );
}

export function CustomerFieldRead() {
  const hook = useContext(ContextFormSupportContract);

  const form = useFormContext();

  return (
    <div>
      <input
        type="number"
        name="customer"
        ref={form.register}
        className="d-none"
      />
      <b>Cliente</b>
      <p>{hook.customer?.name?.commercial}</p>
    </div>
  );
}

export function Hours() {
  const hook = useFormContext();

  const [time, setTime] = useState(0);

  return (
    <div>
      <Label>Horas contratadas - {timeSpent(time)}</Label>
      <input
        type="number"
        placeholder="Escribe aquí"
        min={0}
        ref={hook.register}
        name="hours"
        onChange={(e) => setTime(Number(e.target.value))}
      />
      <p className="text-danger font-weight-bold m-0">
        {hook.errors?.hours?.message}
      </p>
    </div>
  );
}

export function Expiration() {
  const hook = useFormContext();

  const [date, setDate] = useState(null);

  return (
    <Controller
      name="expiration"
      control={hook.control}
      render={({ onChange, value }) => (
        <div>
          <Label>Fecha de vencimiento</Label>
          <DatePicker
            isClearable={true}
            onChange={(data) => {
              const dateToUse = data === null ? null : dateToDbFormat(data);
              onChange(dateToUse);
              setDate(data);
            }}
            dateFormat="dd/MMM/yyyy"
            locale="es"
            selected={
              value === undefined || value === null
                ? null
                : new Date(`${value}:`)
            }
            clearButtonTitle="Limpiar"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            withPortal
            showIcon
            dropdownMode="select"
            placeholderText={"Selecciona una fecha"}
            minDate={dateAtCeroHours(add(new Date(), { days: 1 }))}
          />

          <p className="text-danger font-weight-bold m-0">
            {hook.errors?.expiration?.message}
          </p>
        </div>
      )}
    />
  );
}
