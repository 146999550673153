import MonthSelect from "components/general/Forms/Selects/Month";
import YearSelect from "components/general/Forms/Selects/Year";
import Table from "components/general/Table";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { getInvoicesCancelated } from "helpers/Apis/Documents";
import {
  dateToDbFormat,
  getDateFromUtc,
  parseDateToTextAndTime,
} from "helpers/dates";
import React, { useEffect, useState } from "react";
import ui from "./styles.module.scss";
import { parseToFolio } from "helpers/documents";
import { mnCurrency } from "helpers/money";

/**
 *
 * @param {import("react-data-table-component").TableProps<import('../../../../../types/invoiceEmitted').InvoicesCancelledI>} props
 * @returns
 */
function TableInvoices(props) {
  return <Table {...props} />;
}

export default function AccountingCancelated() {
  function getLastDateOfPreviousMonth() {
    const now = new Date(); // Current date
    // Set the date to the first day of the current month and subtract one day
    const lastDayPreviousMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    return lastDayPreviousMonth;
  }

  function addHoursToUtcDate(utcDateString, hoursToAdd) {
    // Parse the UTC date string into a Date object
    const utcDate = new Date(utcDateString);

    // Add the specified number of hours
    utcDate.setUTCHours(utcDate.getUTCHours() + hoursToAdd);

    // Convert the updated Date object back to a UTC string
    return utcDate.toISOString();
  }

  const [state, setState] = useState({
    state: "loading",
    invoices: [],
    month: getLastDateOfPreviousMonth().getMonth() + 1,
    year: getLastDateOfPreviousMonth().getFullYear(),
    refetch: false,
  });

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        state: "loading",
      }));

      const invoices = await getInvoicesCancelated({
        beginDate: dateToDbFormat(new Date(state.year, state.month - 1, 1)),
        endDate: dateToDbFormat(new Date(state.year, state.month, 0)),
      });

      setState((current) => ({
        ...current,
        state: "none",
        invoices,
      }));
    })();
  }, [state.refetch]);

  const setMonth = (month) => {
    setState((current) => ({
      ...current,
      month,
    }));
  };

  const setYear = (year) => {
    setState((current) => ({
      ...current,
      year,
    }));
  };

  return (
    <main>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/contabilidad",
            text: "Contabilidad",
          },
          {
            route: "/contabilidad/facturas-canceladas",
            text: "CFDI Cancelados",
          },
        ]}
      />

      <div className={ui.content}>
        <div className={ui.filters}>
          <MonthSelect value={state.month} onChange={setMonth} />
          <YearSelect
            from={new Date().getFullYear() - 10}
            value={state.year}
            onChange={setYear}
          />
          <ButtonV2
            onClick={() =>
              setState((current) => ({
                ...current,
                refetch: !current.refetch,
              }))
            }
          >
            <span>Buscar</span>
          </ButtonV2>
        </div>

        <TableInvoices
          pagination={false}
          sortServer={false}
          progressPending={state.state === "loading"}
          data={state.invoices}
          expandableRows={false}
          columns={[
            {
              name: "Folio",
              selector: (item) => parseToFolio(+item.folio),
              center: true,
              width: "7.55%",
            },
            {
              name: "Tipo",
              selector: (item) => item.type,
              sortable: true,
            },
            {
              name: "Cliente",
              selector: (item) => item.socialReason,
              sortable: true,
            },

            {
              name: "Cancelado",
              width: "15%",
              selector: (item) =>
                `${parseDateToTextAndTime(item.invoice.Date, "medium")}`,
            },
            {
              name: "Emisión",
              width: "15%",

              selector: (item) =>
                `${parseDateToTextAndTime(
                  item.invoice.Type.split("-")[0].trim() === "I"
                    ? getDateFromUtc(addHoursToUtcDate(item.creationDate, 5))
                    : getDateFromUtc(item.creationDate),
                  "medium"
                )}`,
            },
            {
              name: "Importe",
              selector: (item) => mnCurrency(item.subTotal),
              style: {
                textAlign: "right", // Aligns only the content, not the header
              },
            },

            {
              name: "IVA",
              selector: (item) => mnCurrency(item.iva),
              style: {
                textAlign: "right", // Aligns only the content, not the header
              },
            },
            {
              name: "Total",
              selector: (item) => mnCurrency(item.total),
              style: {
                textAlign: "right", // Aligns only the content, not the header
              },
            },
            {
              name: "Factura",
              selector: (item) =>
                item.invoice.Type.split("-")[0].trim() === "E"
                  ? parseToFolio(+item.reference)
                  : "-",
              center: true,
              width: "7.55%",
            },
          ]}
        />
      </div>
    </main>
  );
}
