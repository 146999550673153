import useDialog from "customHooks/useDialog";
import React from "react";
import Dialog from "structure/Dialog";
import FormComments, {
  Comment,
  HoursUsed,
  Subject,
  Submit,
  Topic
} from "../FormComments";
import ui from "./styles.module.scss";

/**
 * Component to hadle the add comment dialog
 * @param {import("./types").PropsAddComment} param0 
 
 */
export default function AddComment({
  children,
  idSupportContract,
  onSuccessSubmit = () => {},
}) {
  const dialog = useDialog();

  return (
    <FormComments
      idSupportContract={idSupportContract}
      onSuccessSubmit={(dto) => {
        dialog.close();
        onSuccessSubmit(dto);
      }}

    >
      <div onClick={dialog.open}>{children}</div>

      <Dialog
        title="Bitacora"
        width={75}
        forwardRef={dialog.refDialog}
        closeCallback={()=>dialog.close()}
        footer={
          <div className="w-100 d-flex align-items-center justify-content-end">
            <Submit />
          </div>
        }
      >
        <div className={ui.formFields}>
          <Subject />
          <Topic/>
          <Comment />
          <HoursUsed />
        </div>
      </Dialog>
    </FormComments>
  );
}
