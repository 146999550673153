import {
  getSupportContracts,
  toggleSupportContractStatus,
} from "helpers/Apis/support";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

/**
 * @type {import("./types").StateSupportContracts}
 */
const INITIAL_STATE = {
  status: "loading",
  search: "",
  selectedAgreement: null,
  data: [],
  noRecordsFound: 0,
  pages: 0,
  page: 1,
  refetch: false,
  rowsPerPage: 10,
  orderDesc: true,
  columnOrder: "ID",
};

/**
 * Handle the support contracts
 * @returns {import("./types").ReturnSupportContracts}
 */
export default function useSupportContracts() {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        status: "loading",
      }));

      const records = await getSupportContracts({
        page: state.page,
        rowsPerPage: state.rowsPerPage,
        search: state.search,
        orderDesc: state.orderDesc,
        columnOrder: state.columnOrder,
      });

      setState((current) => ({
        ...current,
        pages: records.pages,
        noRecordsFound: records.noRecordsFound,
        data: records.data,
        status: "none",
        selectedAgreement: null,
      }));
    })();
  }, [
    state.refetch,
    state.page,
    state.columnOrder,
    state.orderDesc,
    state.rowsPerPage,
  ]);



  const refetch = () => {
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
      page: 1,
    }));
  };

  const refetchCurrentPage = () => setState((current) => ({ ...current, refetch: !current.refetch }));

  const attemptToggleStatus = async () => {
    let response = false;

    if (state.selectedAgreement.Estatus === true) {
      const { isConfirmed } = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¿Deseas inactivar este contrato de soporte?",
        showCancelButton: true,
        cancelButtonText: "No, regresar",
        confirmButtonText: "Sí, inactivar",
        icon: "question",
      });

      response = isConfirmed;
    } else {
      const { isConfirmed } = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¿Deseas reactivar este contrato de soporte?",
        showCancelButton: true,
        cancelButtonText: "No, regresar",
        confirmButtonText: "Sí, re-activar",
        icon: "question",
      });

      response = isConfirmed;
    }

    if (response) {
      const success = await toggleSupportContractStatus(state.selectedAgreement.ID);

      if(success) refetch();
    }
  };

  const setContract = (idRecord) => {
    const item = state.data.find((item) => item.ID === idRecord);

    setState((current) => ({
      ...current,
      selectedAgreement: item,
    }));
  };

  const setSearch = (search) => setState((current) => ({ ...current, search }));

  const setOrderColumn = (order) =>
    setState((current) => ({
      ...current,
      columnOrder: order,
      page: 1,
      orderDesc: current.columnOrder === order ? !current.orderDesc : true,
    }));

  const setRowsPerPage = (rowsPerPage) =>
    setState((current) => ({ ...current, rowsPerPage, page: 1 }));

  const setPage = (page) => setState((current) => ({ ...current, page }));

  const updateHoursUsed = (hours,id) => {
    let itemToUpdate = state.data.find((item) => item.ID === id);
    itemToUpdate.HorasUtilizadas = itemToUpdate.HorasUtilizadas + hours;
    let indexItem = state.data.findIndex((item) => item.ID === id);
    let unrefItems = [...state.data];
    unrefItems[indexItem] = itemToUpdate;

    setState(current=>({
      ...current,
      data:unrefItems
    }));
  }

  return {
    ...state,
    setContract,
    refetch,
    setSearch,
    setOrderColumn,
    setRowsPerPage,
    setPage,
    attemptToggleStatus,
    updateHoursUsed,
    refetchCurrentPage
  };
}
