// import { Modal } from "components/general/Modal";
import useDialog from "customHooks/useDialog";
import React, { useEffect } from "react";
import MassiveInvoiceDownload from "structure/MassiveInvoiceDownload";
import Dialog from "structure/Dialog";

export default function ModalMassiveInvoiceDownload({ children }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const dialog = useDialog();

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  useEffect(() => {
    if (isOpen){
        dialog.open();
        return
    }

    dialog.close();
  }, [isOpen]);

  return (
    <>
      <div onClick={open}>{children}</div>

      <Dialog
        title={"Descargar facturas"}
        forwardRef={dialog.refDialog}
        width={50}
        closeCallback={close}
      >
        {isOpen && <MassiveInvoiceDownload />}
      </Dialog>
    </>
  );
}
