import { dateToDbFormat } from "helpers/dates";
import * as yup from "yup";

export const tag = yup
  .mixed()
  .test(
    "integer-or-string",
    "Selecciona una opción del combo o escribe un nuevo tag para crearlo",
    (value) => {
      if (typeof value === "string") return true;

      // Si es un número entero, es válido
      if (typeof value === "number" && Number.isInteger(value)) {
        return true;
      }

      // Si es una cadena, intentar convertirla a número y verificar si es entero
      if (typeof value === "string" && !isNaN(value)) {
        const parsedValue = parseInt(value, 10);
        return Number.isInteger(parsedValue);
      }

      return false; // Si no cumple ninguna de las condiciones
    }
  )
  .required("Obligatorio");

const hoursRequired = yup
  .number()
  .required("Obligatorio")
  .typeError("Debe ser un número")
  .min(0, "Mínimo 0 horas");

const customer = yup.number().required("Obligatorio").min(1, "Obligatorio");

const expiration = yup
  .date()
  .nullable(true)
  .default(null)
  .typeError("Debe ser una fecha")
  .transform((parsed, input) => {
    if (parsed !== null && parsed !== undefined) return parsed;

    return null;
  });

export const schemaAdd = yup.object().shape({
  tag,
  hours: hoursRequired,
  customer,
  expiration,
});
