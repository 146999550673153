import React, { useContext, useRef } from "react";
import { ContextContract } from "..";
import GridLaoding from "components/individual/GridLoading";
import ui from "./styles.module.scss";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import { Pagination } from "components/individual/Pagination";
import Mobile from "./Mobile";

const Header = () => (
  <div className={ui.header}>
    <p>No. doc.</p>
    <p>Razón social</p>
    <p>Ejecutivo</p>
    <p>Moneda</p>
    <p>Total</p>
    <p>Registro</p>
    <p>Recordatorio</p>
    <p>Vencimientos</p>
    <p>Estatus</p>
  </div>
);

export default function TableContract() {
  const {
    isFetchingData,
    recordsWereFounded,
    rows,
    setContract,
    page,
    totalPages,
    setPage,
  } = useContext(ContextContract);

  const key = useRef(`${window.crypto.randomUUID()}`);

  if (isFetchingData)
    return (
      <div className={ui.loading}>
        <div className={ui.table}>
          <Header />
          <div className={ui.contentRows}>
            <GridLaoding />
          </div>
        </div>
      </div>
    );

  if (!recordsWereFounded)
    return (
      <h1 className="text-center">
        No se encontraron contratos con los filtros solicitados
      </h1>
    );

  return (
    <div className={ui.table}>
      <Header />
      <div className={ui.contentRows}>
        {rows.map((contract, i) => (
          <div key={`${key.current}-${i}`}>
            <div>
              <input
                onChange={(e) => setContract(contract)}
                type="radio"
                className="d-none"
                id={`${key.current}-input-${i}`}
                name={key.current}
              />
              <label className={ui.row} htmlFor={`${key.current}-input-${i}`}>
                <ModalDocsRelatedV2 fromLegal={false} idDocument={contract.id}>
                  <p className={ui.moreInfo}>{contract.numeroDocumento}</p>
                </ModalDocsRelatedV2>
                <p>{contract.razonSocial}</p>
                <p>{contract.iniciales}</p>
                <p className="text-center">{contract.moneda}</p>
                <p className="text-right">{contract.total.texto}</p>
                <p className="text-center">
                  {parseDateToText(`${contract.registro.yyyymmdd}:`)}
                </p>
                <p className="text-center">{parseDateToText(getDateFromUtc(contract.reminder))}</p>
                <p className="text-center">
                  {parseDateToText(`${contract.facturar.yyyymmdd}:`)}
                </p>
                <p>{contract.estatus}</p>
              </label>
            </div>

            <Mobile {...contract} />
          </div>
        ))}
      </div>
      <Pagination actualPage={page} pages={totalPages} onChange={setPage} />
    </div>
  );
}
