import * as yup from "yup";
import { tag } from "../contracts";

const comment = yup.string().required("Obligatorio");

const subject = yup
  .string()
  .required("Obligatorio")
  .min(1, "Al menos 1 caracter")
  .max(256, "Máximo 256 caracteres");

const supportContract = yup
  .number()
  .required("Obligatorio")
  .min(1, "Obligatorio");

const hours = yup.number().required("Obl1igatorio").min(0, "Mínimo 0 horas").typeError("Obligatorio");

const schema = yup.object().shape({
  comment,
  subject,
  // supportContract,
  tag,
  hours,
});


export default schema;