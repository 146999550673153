import { yupResolver } from "@hookform/resolvers/yup";
import React, { createContext, useContext, useState } from "react";
import {
  useForm,
  Controller,
  useFormContext,
  FormProvider,
} from "react-hook-form";
import schema from "Schemas/Support/comments";
import CommentBox from "components/individual/CommentBox";
import { Label } from "structure/Document";
import { timeSpent } from "helpers/dates";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import { addSupportContractComment } from "helpers/Apis/support";
import TagsSupport from "../Tags";
/**
 * @type {import("react").Context<import("./types").StateForm>}
 */
const ContextFormComments = createContext(undefined);

/**
 * @type {import("./types").StateForm}
 */
const INITIAL_STATE = {
  idForm: window.crypto.randomUUID(),
  status: "none",
};

/**
 * Render a form to fill the tracking of a support contract
 * @param {import('./types').PropsFormComments} props - Props
 */
export default function FormComments(props) {
  const [state, setState] = useState({
    INITIAL_STATE,
    idForm: window.crypto.randomUUID(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    criteriaMode: "all",
    shouldFocusError: true,
    mode: "onChange",
  });

  /**
   * Handle create a new comment into a support contract
   * @param {import("./types").DtoFormCommentSupport} dto - Information of the form
   */
  const handleSubmit = async (dto) => {
    setState((current) => ({
      ...current,
      status: "loading",
    }));

    const wasCreated = await addSupportContractComment({
      comment: dto.comment,
      hours: dto.hours,
      subject: dto.subject,
      supportAgreementId: props.idSupportContract,
      tag:dto.tag
    });

    setState((current) => ({
      ...current,
      status: "none",
    }));

    if (wasCreated && props?.onSuccessSubmit !== undefined) {
      props?.onSuccessSubmit(dto);
      form.reset();
    }
  };

  return (
    <ContextFormComments.Provider value={state}>
      <FormProvider {...form}>
        <form
          {...props}
          id={state.idForm}
          onSubmit={form.handleSubmit(handleSubmit, console.log)}
        >
          {props.children}
        </form>
      </FormProvider>
    </ContextFormComments.Provider>
  );
}

// TODO: Crear un componente para el tag del asunto por aparte y solo importarlo aqui para no casar el codigo con este formulario
export function Topic() {
  const form = useFormContext();

  return (
    <Controller
      name="tag"
      control={form.control}
      render={({ onChange, value }) => (
        <div>
          <TagsSupport onChange={(tag) => onChange(tag.value)} type="tema" value={value}/>
          <p className="text-danger font-weight-bold m-0">
            {form.errors?.tag?.message}
          </p>
        </div>
      )}
    />
  );
}

export function Comment() {
  const form = useFormContext();

  return (
    <Controller
      name="comment"
      control={form.control}
      render={({ onChange, value }) => (
        <div>
          <Label>Comentarios</Label>
          <CommentBox onChange={onChange} value={value} />
          <p className="text-danger font-weight-bold m-0">
            {form.errors?.comment?.message}
          </p>
        </div>
      )}
    />
  );
}

export function HoursUsed() {
  const [state, setState] = useState({
    time: 0,
  });

  const state2 = useContext(ContextFormComments);

  const form = useFormContext();

  return (
    <div>
      <Label>
        Horas invertidas{" "}
        {state.time > 0 && (
          <p className="m-0 ml-2 font-italic"> - {timeSpent(state.time)}</p>
        )}
      </Label>
      <input
        type="number"
        name="hours"
        placeholder="Escribe aquí"
        step={"0.01"}
        disabled={state2.status === "loading"}
        ref={form.register}
        onChange={(e) =>
          setState((current) => ({
            ...current,
            time: Number(e.target.value),
          }))
        }
      />
      <p className="text-danger font-weight-bold m-0">
        {form.errors?.hours?.message}
      </p>
    </div>
  );
}

export function Subject() {
  const [text, setText] = useState("");

  const state = useContext(ContextFormComments);

  const form = useFormContext();

  return (
    <div>
      <Label required={text === ""} currentLength={text.length} maxLength={256}>
        Tema
      </Label>

      <input
        ref={form.register}
        type="text"
        placeholder="Escribe aquí"
        maxLength={256}
        name="subject"
        onChange={(e) => setText(e.target.value)}
        disabled={state.status === "loading"}
      />

      <p className="text-danger font-weight-bold m-0">
        {form.errors?.subject?.message}
      </p>
    </div>
  );
}

export function Submit() {
  const state = useContext(ContextFormComments);

  if (state.status === "loading")
    return <Spinner text={"Agregando comentario"} hidden={false} />;

  return (
    <ButtonV2 type="submit" form={state.idForm}>
      <span>Comentar</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}
