/**
 * @typedef {Object} MonthRange
 * @property {Date} lastDay - Last Day of the month
 * @property {Date} firstDay - First day of the month
 * @property {string} rangeText - Range of the month with text
 */

/**
 * @typedef {Object} NumberDate
 * @property {number} year - Year date
 * @property {number} month - Month date
 * @property {number} day - Day date
 * @property {string} monthName - Month name
 * @property {string} monthAbrev - Month abreviation
 * @property {string} isoDate - Iso date
 */

import { intervalToDuration } from "date-fns";
import "../types/typedef/dates";

export const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

/**
 * List of months
 * @type {import("types/dates").Month[]}
 */
export const MONTH_LIST = [
  {
    name: "Enero",
    value: 1,
  },
  {
    name: "Febrero",
    value: 2,
  },
  {
    name: "Marzo",
    value: 3,
  },
  {
    name: "Abril",
    value: 4,
  },
  {
    name: "Mayo",
    value: 5,
  },
  {
    name: "Junio",
    value: 6,
  },
  {
    name: "Julio",
    value: 7,
  },
  {
    name: "Agosto",
    value: 8,
  },
  {
    name: "Septiembre",
    value: 9,
  },
  {
    name: "Octubre",
    value: 10,
  },
  {
    name: "Noviembre",
    value: 11,
  },
  {
    name: "Diciembre",
    value: 12,
  },
];

/**
 * Get the last day of the current previous month
 * @returns {Date}
 * @example
 * 17-03-2023 -> 28-02-2023
 */
export function getLastDayOfPreviousMonth() {
  const currentDay = new Date();
  const calculatedMaxDate = new Date(
    currentDay.getFullYear(),
    currentDay.getMonth(),
    0
  );

  return calculatedMaxDate;
}

/**
 * Get the last day of the date requested
 * @param {Date} date - Date to query
 * @returns {Date} Last day of the current date requested
 */
export function getLastDayOfDate(date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
}

/**
 * Get the first day of the current month
 * @param {Date} date - Date to query his first day
 * @returns {Date}
 */
export function getFirstDayOfMonth(date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return firstDay;
}

/**
 * Format a date to something readable
 * @param {Date|string} date - Date to format
 * @param {"medium" | "full" | "long" | "short"} dateStyle - Date style
 * @returns {string}
 */
export function parseDateToText(date, dateStyle = "medium") {
  if (date === null || date === undefined) return "";

  const dateToUse =  new Date(date);

  try {
    if (dateStyle === "medium") {
      const day = Intl.DateTimeFormat("es-MX", {
        day: "2-digit",
      }).format(dateToUse);

      const month = Intl.DateTimeFormat("es-MX", {
        month: "short",
      }).format(dateToUse);

      const parsedMonth = month.substring(1, 3);
      const capitalizedMonth = month.substring(0, 1).toUpperCase();

      const year = Intl.DateTimeFormat("es-MX", {
        year: "2-digit",
      }).format(dateToUse);

      return `${day}/${capitalizedMonth}${parsedMonth}/${year}`;
    }

    return new Intl.DateTimeFormat("es-MX", {
      dateStyle,
    }).format(new Date(date));
  } catch (e) {
    return "Invalid Date";
  }
}

/**
 * Display a user friendly date with time
 * @param {Date|string} date - Date
 * @param {"medium" | "full" | "long" | "short"} timeStyle - Time style
 * @returns {string}
 */
export function parseDateToTime(date, timeStyle = "medium") {
  if (date === null || date === undefined) return "";

  return new Intl.DateTimeFormat("es-MX", {
    timeStyle,
    hour12: true,
  }).format(new Date(date));
}

/**
 * Format a date to something readable
 * @param {Date|string} date - Date to format
 * @param {"medium" | "full" | "long" | "short"} dateStyle - Date style
 * @returns {string}
 */
export function parseDateToTextAndTime(date, dateStyle) {
  const dateParsed = parseDateToText(date, dateStyle);
  const timeParsed = parseDateToTime(date, dateStyle);

  return `${dateParsed} ${timeParsed}`;
}

export const getMonthList = () => MONTH_LIST;

/**
 * Get the months available to chose according the creation date and the current date
 * in order to select backward or forward
 * @param {Date} minDate - Min date to select
 * @param {Date} requestedDate - In order to know which month can be displayed forward or backford
 * @param {Date} maxDate - Max date that can be filtered
 * @example
 * const monthsListAvailable = getMonthRanges(
 *  new Date('2021-06-01'),
 *  new Date('2022-04-1'),
 *  new Date('2022-09-1')
 * );
 *
 * console.log(monthsListAvailable) // [{"name":"Enero","value":1},{"name":"Febrero","value":2},{"name":"Marzo","value":3},{"name":"Abril","value":4},{"name":"Mayo","value":5},{"name":"Junio","value":6},{"name":"Julio","value":7},{"name":"Agosto","value":8},{"name":"Septiembre","value":9}]
 */
export const getMonthRanges = (
  minDate = new Date(),
  requestedDate = new Date(),
  maxDate = new Date()
) => {
  // const parsedMinDate = new Date(minDate);

  let months = [];

  for (let i = 1; i < 13; i++) {
    months.push(new Date(`${requestedDate.getFullYear()}-${i}-1`));
  }

  const monthsAllowed = months.reduce((months, currentMonth, i) => {
    // console.log({
    //   requestedDate,
    //   minDate,
    //   maxDate,
    // });
    if (currentMonth >= minDate && currentMonth <= maxDate) {
      return [...months, MONTH_LIST[i]];
    }
    return months;
  }, []);

  // console.log(monthsAllowed);
  return monthsAllowed;

  // //////

  // const monthsAllowed = months.reduce((months, currentMonth, i) => {
  //   if (requestedDate >= parsedMinDate && requestedDate >= currentMonth) {
  //     return [...months, MONTH_LIST[i]];
  //   }
  //   return months;
  // }, []);

  // if (monthsAllowed.length > 0) {
  //   return monthsAllowed;
  // }

  // const monthsToUse = months.reduce((months, currentMonth, i) => {
  //   if (currentMonth >= parsedMinDate) {
  //     return [...months, MONTH_LIST[i]];
  //   }
  //   return months;
  // }, []);

  // return monthsToUse;
};

export const MONTHS_ABREVIATION = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

/**
 * Get the month, day and year by separate of a JS Date
 *
 * @param {Date} date - JS Date
 * @returns {NumberDate} Day, month, year and monthName by separate
 */
export function getNumberDate(date) {
  const isoDate = new Date(date).toISOString();

  // const isoDate =
  //   typeof date === "string"
  //     ? new Date(date).toISOString()
  //     : date.toISOString();

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const monthName = MONTHS[month - 1];
  const monthAbrev = MONTHS_ABREVIATION[month - 1];

  return { day, month, year, monthName, monthAbrev, isoDate };
}

/**
 * Returns the first day and last day of the year
 * @returns {import("./Apis/datesTypes").RangeYear}
 */
export function rangeFullYear() {
  const firstDay = new Date(new Date().getFullYear(), 0, 1);
  const lastDay = new Date(new Date().getFullYear(), 11, 31);

  let parsedFirstDay = getNumberDate(firstDay);

  parsedFirstDay = {
    ...parsedFirstDay,
    jsDate: firstDay,
  };

  let parsedLastDay = getNumberDate(lastDay);

  parsedLastDay = {
    ...parsedLastDay,
    jsDate: lastDay,
  };

  return {
    firstDay: parsedFirstDay,
    lastDay: parsedLastDay,
  };
}

/**
 * Convert a JS date into a string of 'database format'. This is nice
 * because mostly db requires the date as YYYY-MONTH-DAY
 * @param {Date} date - JS Date
 * @returns {string} Formated date as db requires YYYY-MONTH-DAY
 */
export function dateToDbFormat(date) {
  const dateToUse = date instanceof Date ? date : new Date(date);

  const dateSeparated = getNumberDate(dateToUse);

  const month =
    dateSeparated.month <= 9 ? `0${dateSeparated.month}` : dateSeparated.month;
  const day =
    dateSeparated.day <= 9 ? `0${dateSeparated.day}` : dateSeparated.day;

  return `${dateSeparated.year}-${month}-${day}`;
}

/**
 * Parse the date to number data, day, month and year by separate
 *
 * @param {string} date - Date like plain text
 * @param {'dmy'|'ymd'} format - Format that has the date
 * @param {'/'|'-'|'_'} symbol - Symbol which separates the data (/,-,_,etc.)
 * @returns {NumberDate}
 */
export function trimDate(date, format, symbol) {
  if (date === undefined) return;

  let day, month, year;

  switch (format) {
    case "dmy":
      [day, month, year] = date.split(symbol);
      return {
        day: parseInt(day, 10),
        month: parseInt(month, 10),
        year: parseInt(year, 10),
        monthName: MONTHS[+month - 1],
        monthAbrev: MONTHS_ABREVIATION[+month - 1],
      };

    case "ymd":
      [year, month, day] = date.split(symbol);
      return {
        day: +day,
        month: +month,
        year: +year,
        monthName: MONTHS[+month - 1],
        monthAbrev: MONTHS_ABREVIATION[+month - 1],
      };

    default:
      break;
  }
}

export const getRangeDate = (data) => {
  const { startDate, endDate } = data;

  const startDateFormated = trimDate(startDate, "dmy", "/");
  const endDateFormated = trimDate(endDate, "dmy", "/");

  const startDateJS = new Date(
    startDateFormated.year,
    startDateFormated.month - 1,
    startDateFormated.day
  );
  const endDateJS = new Date(
    endDateFormated.year,
    endDateFormated.month - 1,
    endDateFormated.day
  );

  return [startDateJS, endDateJS];
};

export const msToMinutes = (ms) => {
  const minutes = Math.floor(ms / 60000);
  const seconds = +((ms % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

/**
 * Get the first day and last day of the actual month
 *
 * @return {MonthRange}
 */
export function monthRange() {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();

  const lastDay = new Date(year, month + 1, 0);
  const firstDay = new Date(year, month, 1);

  const lastDayNumber = getNumberDate(lastDay);
  const firstDayNumber = getNumberDate(firstDay);

  const beginText = `${firstDayNumber.day}/${firstDayNumber.monthName}/${firstDayNumber.year}`;
  const endText = `${lastDayNumber.day}/${lastDayNumber.monthName}/${lastDayNumber.year}`;

  const beginRangeNumber = `${firstDayNumber.day}/${
    month < 10 ? "0" + month : month
  }/${firstDayNumber.year}`;

  const endRangeNumber = `${lastDayNumber.day}/${
    month < 10 ? "0" + (month + 1) : month + 1
  }/${lastDayNumber.year}`;

  return {
    lastDay,
    lastDayNumber,
    lastMonth: month + 1,
    firstDay,
    firstDayNumber,
    firstMonth: month,
    rangeText: `${beginText} - ${endText}`,
    rangeNumber: `${beginRangeNumber} - ${endRangeNumber}`,
  };
}

/**
 * Substract days to a date
 *
 * @param {Date} date - Date
 * @param {number} substractDays - Days to substract to the date
 * @return {Date} Date substracted
 */
export const substractDays = (date, substractDays) => {
  const unreferencedDate = date.toISOString();
  const copyDate = new Date(unreferencedDate);

  copyDate.setDate(copyDate.getDate() - substractDays);

  return copyDate;
};

/**
 * Get the date provided with cero hours
 * @param {Date} date - Date with midnight time
 */
export function dateAtCeroHours(date) {
  const unrefDate = new Date(date);

  return new Date(unrefDate.setHours(0, 0, 0));
}

/**
 * Add days to another date
 * @param {Date} date - JS Date
 * @param {number} daysToAdd - Days to add
 * @returns {Date} Date with the added days
 */
export const addDays = (date, daysToAdd) => {
  const unreferencedDate = date.toISOString();
  const copyDate = new Date(unreferencedDate);

  copyDate.setDate(copyDate.getDate() + daysToAdd);

  return copyDate;
};

/**
 * Get the information of the month period selected
 * @param {number} month - Month selected
 * @param {number} year - Year selected
 * @returns {object}
 */
export const getMonthPeriod = (month, year) => {
  const daysInMonth = new Date(year, month, 0).getDate();

  const begin = new Date(year, month - 1, 1);
  const end = new Date(year, month - 1, daysInMonth);

  const beginString = new Intl.DateTimeFormat("es-ES", {
    dateStyle: "long",
  }).format(begin);

  const endString = new Intl.DateTimeFormat("es-ES", {
    dateStyle: "long",
  }).format(end);

  return {
    begin,
    end,
    id: 1,
    message: `Correspondiente al período ${beginString} - ${endString}`,
    value: month,
  };
};

/**
 * Get the information of the range date period
 * @param {Date} start - Start date
 * @param {Date} end - End date
 * @returns {object}
 */
export const getRangePeriod = (start, end) => {
  const beginString = new Intl.DateTimeFormat("es-ES", {
    dateStyle: "long",
  }).format(start);

  const endString = new Intl.DateTimeFormat("es-ES", {
    dateStyle: "long",
  }).format(end);

  const { days } = intervalToDuration({
    start,
    end,
  });

  return {
    id: 2,
    begin: start,
    end: end,
    message: `Correspondiente al período ${beginString} - ${endString}`,
    value: days,
  };
};

/**
 * Get the range of the current year
 * @returns {import("types/dates").YearRangeI}
 */
export const getYearRange = () => {
  const begin = new Date(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const end = new Date(
    new Date(new Date().getFullYear(), 11, 31).setHours(0, 0, 0, 0)
  );

  return {
    begin: {
      js: begin,
      db: dateToDbFormat(begin),
    },
    end: {
      js: end,
      db: dateToDbFormat(end),
    },
  };
};

/**
 * Convert a date without hours
 * @param {Date} date - Date to convert
 * @returns {Date}
 */
export const dateWithCeroHours = (date) => new Date(date.setHours(0, 0, 0, 0));

/**
 * Convert utc date to a locale zone of the current client
 * @param {string} date - String date as utc
 * @returns {Date} Date formated from UTC to the locale zone of the client
 */
export const getDateFromUtc = (date) => {
  const [utcDate, time] = date.split("T");
  const [year, month, day] = utcDate.split("-");
  const [hh, mm] = time.split(":");

  const utcFormated = Date.UTC(+year, +month - 1, day, hh, mm);

  return new Date(utcFormated);
};

/**
 * Formate the date
 * @param {string} date
 * @returns {string}
 */
export const formateDate = (date) => {
  const dateToTransfom = new Date(date);
  const day =
    dateToTransfom.toLocaleDateString("en-US", { day: "numeric" }) < 10
      ? `0${dateToTransfom.toLocaleDateString("en-US", { day: "numeric" })}`
      : dateToTransfom.toLocaleDateString("en-US", { day: "numeric" });
  const month = dateToTransfom.toLocaleDateString("es-ES", { month: "short" });
  const year = dateToTransfom.toLocaleDateString("en-US", { year: "numeric" });
  const trasnfometDate = `${day}/${month}/${year}`;
  return trasnfometDate;
};

/**
 * Get the date with lowest value among the array
 * @param {string[]} dateStrings - Dates must be on format **YYYY-MM-DD**
 * @returns {string} Date with the lowest value
 */
export function getLowestDate(dateStrings) {
  // Convert date strings to Date objects
  const dates = dateStrings.map((dateString) => new Date(dateString));

  // Find the lowest date using Array.reduce()
  const lowestDate = dates.reduce((minDate, currentDate) => {
    return currentDate < minDate ? currentDate : minDate;
  });

  // Convert the lowestDate back to the "yyyy-mm-dd" format
  return lowestDate.toISOString().slice(0, 10);
}

/**
 * Parse a utc string to something readable as text
 * @param {string} date - `UTC Date` as string
 * @returns {string}
 */
export function parseUtcToText(date) {
  let parsedUtc;

  try {
    parsedUtc = getDateFromUtc(date);
  } catch (error) {
    return "ND";
  }

  try {
    return parseDateToText(parsedUtc);
  } catch (error) {
    return "ND";
  }
}

export function getMondayDate() {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday (0)
  const monday = new Date(today.setDate(diff));
  return monday;
}

/**
 * 
 * @param {number} decimalHours 
 * @returns {string}
 * @example
 * // Example usage:
console.log(timeSpent(0.5));  // Output: "0 hour(s) and 30 minute(s)"
console.log(timeSpent(1.25)); // Output: "1 hour(s) and 15 minute(s)"
console.log(timeSpent(2.75)); // Output: "2 hour(s) and 45 minute(s)"
 */
export function timeSpent(decimalHours) {

  if(decimalHours<=0) return '0 horas';

  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);

  const hoursString = hours <= 0 ? '' : `${hours} hora${hours >= 2 ? 's' : ''}`;

  const prefixTime = hours >= 1 && minutes >= 1 ? ' y ' : '';

  const minutesString = minutes <= 0 ? '' : `${minutes} minuto${minutes >= 2 ? 's' : ''}`;

  return `${hoursString} ${prefixTime} ${minutesString}`.trim();
}
