import { monthRange } from "helpers/dates";
import { useState } from "react";
import { saveAs } from "file-saver";
import { massiveInvoicesOverview } from "helpers/Apis/invoice";
import { downloadFeFiles } from "helpers/Apis/associateFilesV2";

/**
 * Handle the download of multiple invoices
 * @returns {import("./types").ReturnMassiveInvoiceDownload}
 */
export default function useMassiveInvoiceDownload() {
  /**
   * @type {import("../types").StateMassiveInvoiceDownload}
   */
  const INITIAL_STATE = {
    from: monthRange().firstDay,
    to: monthRange().lastDay,
    state: "none",
    invoicesFound: undefined,
    invoicesDownloaded: undefined,
    step: 0,
    overviewInvoices:[]
  };

  const [state, setState] = useState(INITIAL_STATE);

  /**
   * Downloads multiple files sequentially with a delay between each download.
   * This approach ensures each file is saved before moving to the next, reducing the chance of download errors.
   *
   * @param {Array<Array<File>>} files - An array of file groups, where each file group is an array of `File` instances to download.
   * @param {number} delay - The delay in milliseconds between each file download.
   *
   * @example
   * const files = [
   *   [file1, file2],  // First group of files to download
   *   [file3, file4],  // Second group of files to download
   *   // Add more file groups as needed
   * ];
   * downloadFiles(files, 500);
   */
  async function downloadFiles(files, delay = 500,ogArray = [...state.overviewInvoices]) {

    let operationArray = [...ogArray];

    for (let fileGroup of files) {
      for (let file of fileGroup) {
        await new Promise((resolve) => {
          saveAs(file);
          setTimeout(resolve, delay);
          const folio = +file.name.split("-")[1].split(".")[0];
          

            const index = ogArray.findIndex((invoice) => invoice.folio === `${folio}`);


            operationArray[index] = {
                ...operationArray[index],
                isDownloaded: true
            }

            console.log(ogArray,operationArray)

          setState(current=>({
            ...current,
            overviewInvoices: operationArray
          }))
        });
      }
    }
  }

  /**
   * Set from range
   * @param {Date} from - Date
   * @returns {void}
   */
  const setFrom = (from) => setState((current) => ({ ...current, from }));

  /**
   * Set to range
   * @param {Date} to - Date
   * @returns {void}
   */
  const setTo = (to) => setState((current) => ({ ...current, to }));

  /**
   * Finish the process of downloading
   * @param {boolean} [foundInvoices=true] 
   * @returns {void}
   */
  const finishDownload = (foundInvoices = true) =>
    setState((current) => ({
      ...current,
      state: "none",
      invoicesDownloaded: undefined,
      invoicesFound: foundInvoices ? undefined : 0,
    }));

  /**
   * Attempt to download all invoices within the specified date range.
   * @returns {Promise<void>}
   */
  const handleMassiveDownload = async () => {
    setState((current) => ({
      ...current,
      state: "downloading",
      overviewInvoices:[]
    }));
    const invoices = await massiveInvoicesOverview(state.from, state.to);

    if (invoices.length <= 0) {
      finishDownload(false);
      return;
    }

    setState((current) => ({
      ...current,
      invoicesFound: invoices.length,
      invoicesDownloaded: 0,
      overviewInvoices: invoices
    }));

    const queueFiles = invoices.map(async (invoice, i) => {
      return await new Promise((resolve, reject) => {
        setTimeout(async () => {
          const files = await downloadFeFiles(invoice.xml, invoice.pdf);

          setState((current) => ({
            ...current,
            invoicesDownloaded: current.invoicesDownloaded + 1,
          }));

          resolve(files);
        }, (i + 1) * 500);
      });
    });

    const files = await Promise.all([...queueFiles]);

    finishDownload();
    await downloadFiles(files, 250,invoices);

  };

  /**
   * Set the step of the current download process
   * @param {number} step - Step of the downlaod process
   * @returns {void}
   */
  const setStep = (step) => setState((current) => ({ ...current, step }));

  return {
    state,
    setFrom,
    setTo,
    finishDownload,
    handleMassiveDownload,
    setStep,
  };
}
