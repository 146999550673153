import Icon from "components/individual/Icon";
import { parseToFolio } from "helpers/documents";
import React, { useState } from "react";
import ui from "./styles.module.scss";
import { downloadFeFiles } from "helpers/Apis/associateFilesV2";
import { saveAs } from "file-saver";

/**
 * Render the file got from the query
 * @param {import("./types").ListFileProps} Props
 */
export default function ListFile({
  folio,
  idXml,
  idPdf,
  wasDownloaded = false,
}) {
  const [state, setState] = useState({
    pdf: false,
    xml: false,
  });

  /**
   *
   * @param {'pdf'|'xml'} type
   */
  const handleDownload = async (type = "pdf") => {
    setState((current) => ({
      ...current,
      [type]: !current[type],
    }));

    const [pdf, xml] = await downloadFeFiles(idXml, idPdf);

    setState((current) => ({
      ...current,
      [type]: !current[type],
    }));

    if (type === "pdf") {
      saveAs(pdf);
    } else {
      saveAs(xml);
    }
  };

  return (
    <li className={ui.item}>
      <span className="mr-2">
        {wasDownloaded && state.pdf === false && state.xml === false
          ? "🆗"
          : "⌚"}
      </span>
      <span onClick={() => handleDownload("pdf")} className={ui.downloadBtn}>
        <Icon nameIcon="pdf" />
      </span>
      <span onClick={() => handleDownload("xml")} className={ui.downloadBtn}>
        <Icon nameIcon="doc" />
      </span>
      <span>Factura emitida {parseToFolio(+folio)}</span>
    </li>
  );
}
