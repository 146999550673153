import React, { createContext } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import useMassiveInvoiceDownload from "./useMassiveInvoiceDownload";
import Step1 from "./Step1";
import Step2 from "./Step2";

/**
 * @type {import("react").Context<import("./useMassiveInvoiceDownload/types").ReturnMassiveInvoiceDownload|undefined>}
 */
export const ContextMassiveInvoiceDownload = createContext(undefined);

export default function MassiveInvoiceDownload() {
  const hook = useMassiveInvoiceDownload();

  return (
    <ContextMassiveInvoiceDownload.Provider value={hook}>
      <div className="p-2">
        <div className="mb-4">
          <Steps current={hook.state.step} onChange={hook.setStep}>
            <Steps.Step title="Configurar permisos" />
            <Steps.Step
              title="Descargar facturas emitidas"
              disabled={hook.state.step <= 1}
            />
          </Steps>
        </div>

        {hook.state.step === 0 && <Step1 />}
        {hook.state.step === 1 && <Step2 />}

        <div className="mt-4 d-flex justify-content-end gap-2">
          <ButtonV2
            onClick={() => hook.setStep(hook.state.step - 1)}
            className="mr-2"
            disabled={hook.state.step === 0}
          >
            <span>Regresar</span>
          </ButtonV2>

          <ButtonV2
            onClick={() => hook.setStep(hook.state.step + 1)}
            disabled={hook.state.step === 1}
          >
            <span>Continuar</span>
          </ButtonV2>
        </div>
      </div>
    </ContextMassiveInvoiceDownload.Provider>
  );
}
