import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React, { useRef } from "react";
import { AssociateFiles } from "structure/AssociateFiles";
import { useParams } from "react-router-dom";

export default function ContractAssociatedFiles() {
  const { id } = useParams();

  const breadcrum = useRef([
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/ventas/contratos",
      text: "Ventas",
    },
    {
      route: "/ventas/contratos",
      text: "Contratos",
    },
  ]);

  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        padding: "0 0 1rem 0",
      }}
    >
      <BreadCrumV2 path={breadcrum.current} />
      
      <div className="container">

      
      <AssociateFiles
        idRegister={+id}
        idTypeEntity={1}
        pathUpload={`documentos/contratos/contrato-id-${id}`}
      />
      </div>
    </div>
  );
}
