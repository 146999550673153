import { getSupportContractComments } from "helpers/Apis/support";
import {
  getDateFromUtc,
  parseDateToTextAndTime,
  timeSpent,
} from "helpers/dates";
import React, { Fragment, useEffect, useState } from "react";
import ui from "./styles.module.scss";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

/**
 * @type {import("./types").StateSupportContactCommentI}
 */
const INITIAL_STATE = {
  state: "loading",
  comments: [],
  key: window.crypto.randomUUID(),
};

export default function Comments({ idSupportContract , refetchFlag }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {

      setState((current) => ({
        ...current,
        comments:[],
        state: "loading",
      }));

      const comments = await getSupportContractComments(idSupportContract);

      setState((current) => ({
        ...current,
        comments,
        state: "none",
      }));
    })();
  }, [refetchFlag]);

  if (state.comments.length <= 0)
    return (
      <>
        <h2 className="w-100 text-center py-3">No hay comentarios</h2>
      </>
    );

  return (
    <>
      {state.comments.map((comment, i) => (
        <Fragment key={`${state.key}-${comment.id}`}>
          <article>
            <h5 className="font-weight-bold">Tema: {comment.subject}</h5>

            <h5 className="font-weight-bold">Asunto: {comment.tag}</h5>

            <h5 className="font-weight-bold">Comentarios</h5>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={comment.comment}
            />
          </article>

          <div className={ui.dateOverview}>
            <div>
              <p>{comment.createdBy},</p>
              <p>
                {parseDateToTextAndTime(
                  getDateFromUtc(comment.createdDate),
                  "full"
                )}
              </p>
            </div>
            <div>
              {/* <p>Horas usadas {comment.hoursUsed}</p> */}
              <p>{timeSpent(comment.hoursUsed)}</p>
            </div>
          </div>

          {i < state.comments.length - 1 && <hr />}
        </Fragment>
      ))}
    </>
  );
}
