import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import InformativeTooltip from "components/individual/InfoTooltip";
import { Search } from "components/individual/Search";
import React, { createContext } from "react";
import ui from "./styles.module.scss";
import { Label } from "structure/Document";
import AddContract from "./AddContract";
import Table from "components/general/Table";
import OptionsContractSupport from "./Options";
import { parseDateToText, timeSpent } from "helpers/dates";
import Icon from "components/individual/HeaderIcon";
import useSupportContracts from "./useSupportContracts";
import Comments from "./Comments";
import AddComment from "./AddComment";

/**
 * Render a table with the support contracts
 * @param {import("react-data-table-component").TableProps<import("./types").SupportContractI>} props
 */
function TableContracts(props) {
  return <Table {...props} />;
}

/**
 * @type {import("react").Context<import("./useSupportContracts/types").ReturnSupportContracts|undefined>}
 */
export const ContextSupportContracts = createContext(undefined);

export default function SupportContracts() {
  const state = useSupportContracts();

  return (
    <ContextSupportContracts.Provider value={state}>
      <main className={ui.container}>
        <BreadCrumV2
          path={[
            {
              route: "/inicio",
              text: "Inicio",
            },
            {
              route: "/soporte/contratos",
              text: "Soporte",
            },
          ]}
        />

        <div className={ui.filter}>
          <div>
            <Label>
              Búsqueda{" "}
              <InformativeTooltip>
                <ul>
                  <li>- Nombre corto del directorio (Cliente)</li>
                  <li>- Tag de contrato (Convenio)</li>
                </ul>
              </InformativeTooltip>
            </Label>
            <Search
              placeholder="Escribe aquí"
              onChange={(value) => state.setSearch(value)}
              onDelete={(value) => state.setSearch("")}
              onSearch={state.refetch}
            />
          </div>

          <ButtonV2 onClick={state.refetch}>
            <span>Buscar</span>
          </ButtonV2>

          <AddContract onSuccess={state.refetch}>
            <ButtonV2>
              <span>Agregar</span>
              <Icon icon="plus" />
            </ButtonV2>
          </AddContract>
        </div>

        <div className={ui.containerOptions}>
          {state.selectedAgreement !== null && <OptionsContractSupport />}
        </div>

        <TableContracts
          expandableRows={true}
          paginationTotalRows={state.noRecordsFound}
          onChangePage={state.setPage}
          onChangeRowsPerPage={(info) => state.setRowsPerPage(info)}
          sortServer={true}
          onSort={(item) => state.setOrderColumn(item.sortField)}
          progressPending={state.status === "loading"}
          onRowClicked={(item) => state.setContract(item.ID)}
          selectableRowSelected={(item) =>
            item.ID === state.selectedAgreement?.ID
          }
          fixedHeaderScrollHeight="100%"
          selectableRowsSingle
          expandableRowsComponent={(data) => (
            <div className="p-3">
              <Comments idSupportContract={data.data.ID} refetchFlag={state.data}/>
              <AddComment
                idSupportContract={data.data.ID}
                onSuccessSubmit={dto=>state.updateHoursUsed(dto.hours,data.data.ID)}
              >
                <Label>Comentarizar</Label>
                <textarea
                  placeholder="Escribe aquí"
                  style={{ height: "90px", resize: "none" }}
                ></textarea>
              </AddComment>
            </div>
          )}
          columns={[
            {
              name: "Convenio",
              selector: (item) => item.TipoContrato,
              sortable: true,
              sortField: "TipoContrato",
            },
            {
              name: "Cliente",
              selector: (item) => item.NombreCliente,
              sortable: true,
              sortField: "NombreCliente",
            },
            {
              name: "Hrs. contratadas",
              selector: (item) => `${timeSpent(item.HorasContratadas)}`,
              sortable: true,
              sortField: "HorasContratadas",
            },
            {
              name: "Vigencia",
              sortField: "Expiracion",
              sortable: true,
              selector: (item) =>
                typeof item.Expiracion === "string"
                  ? parseDateToText(`${item.Expiracion.split("T")[0]}:`)
                  : "NA",
            },
            {
              name: "Hrs. utilizadas",
              selector: (item) => `${timeSpent(item.HorasUtilizadas)}`,
              sortable: true,
              sortField: "HorasUtilizadas",
            },
            {
              name: "Hrs. restantes",
              selector: (item) =>
                `${timeSpent(item.HorasContratadas - item.HorasUtilizadas)}`,
              sortable: false,
            },
            {
              name: "Estatus",
              selector: (item) => (item.Estatus ? "Activo" : "Inactivo"),
              sortable: true,
              sortField: "Estatus",
            },
          ]}
          data={state.data}
        />
      </main>
    </ContextSupportContracts.Provider>
  );
}
