import axios from "axios";
import { URL_BASE } from "routes/routes";
import { arrayToQueryParam } from "../fetch";
import Swal from "sweetalert2";

export async function getTags(type) {
  try {
    const queryParams = arrayToQueryParam([
      `${type === "contratos" ? "type=type" : "type=subject"}`,
    ]);

    /**
     * @type {import("axios").AxiosResponse<import('../../../../../server/models/support/types').SupportAgreementsTags[]>}
     */
    const tags = await axios.get(`${URL_BASE}soporte/tags${queryParams}`, {
      withCredentials: true,
    });

    return tags.data;
  } catch (error) {
    return [];
  }
}

/**
 * Create a support contract
 * @param {import("../../../../../server/models/support/types").DtoCreateSupportContract} dto - Dto in order to create a support contract
 */
export async function addSupportContract(dto) {
  try {
    const queryParams = arrayToQueryParam([
      `tag=${dto.tag}`,
      `customer=${dto.customer}`,
      `hours=${dto.hours}`,
      `createdBy=${dto.createdBy}`,
      dto.expiration !== null && dto.expiration !== undefined
        ? `expiration=${dto.expiration}`
        : null,
    ]);

    const res = await axios.post(
      `${URL_BASE}soporte/contrato${queryParams}`,
      {},
      {
        withCredentials: true,
      }
    );

    Swal.fire({
      icon: "success",
      title: "Contrato agregado",
      text: res.data.message,
    });

    return true;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: error.res.data.message,
    });

    return false;
  }
}

/**
 * Update a support contract
 * @param {import("../../../../../server/models/support/types").DtoUpdateSupportContract} dto 
 */
export async function updateSupportContract(dto){
  try {

    const res = await axios.put(
      `${URL_BASE}soporte/contrato`,
      dto,
      {
        withCredentials: true,
      }
    );

    Swal.fire({
      icon: "success",
      title: "Contrato agregado",
      text: res.data.message,
    });

    return true;
  } catch (error) {

    console.log(error);

    Swal.fire({
      icon: "error",
      title: "Error",
      text: error.data.message,
    });


    return false;
  }
}

/**
 *
 * @param {object} params
 * @param {number} params.page - Page requested
 * @param {number} params.rowsPerPage - Rows per page
 * @param {string} params.columnOrder - Column in order to sort the information
 * @param {boolean} params.orderDesc - DESC or ASC order
 * @param {string} params.search - Search text box
 * @returns {Promise<import("../../../../../types/proyects").TabulatedData<import("../../../../../server/models/support/types").SupportContractI>>}
 */
export async function getSupportContracts({
  page = 1,
  rowsPerPage = 100,
  columnOrder = "date",
  orderDesc = true,
  search = "",
}) {
  try {
    const queryParams = arrayToQueryParam([
      `page=${page}`,
      `rowsPerPage=${rowsPerPage}`,
      `columnOrder=${columnOrder}`,
      `orderDesc=${orderDesc}`,
      typeof search !== "string" || search === "" ? null : `search=${search}`,
    ]);

    const tags = await axios.get(`${URL_BASE}soporte/contrato${queryParams}`, {
      withCredentials: true,
    });

    return tags.data;
  } catch (error) {
    return {
      data: [],
      pages: 0,
      noRecordsFound: 0,
    };
  }
}

/**
 *
 * @param {number} id - Id of the contract
 * @returns
 */
export async function getSupportContract(id) {
  if (typeof id !== "number") return null;

  try {
    const res = await axios.get(`${URL_BASE}soporte/contrato/${id}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return null;
  }
}

export async function addSupportContractComment(dto) {
  try {
    const res = await axios.post(
      `${URL_BASE}soporte/contrato/comentario`,
      dto,
      {
        withCredentials: true,
      }
    );

    Swal.fire({
      title: "Éxito",
      text: res.data.message,
      icon: "success",
    });

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Retrieve the comments of a support contract
 * @param {number} id - Id of the contract support to fetch the comments
 */
export async function getSupportContractComments(id) {
  try {
    /**
     * @type {import("axios").AxiosResponse<import("./types").SupportContractCommentI[]>}
     */
    const res = await axios.get(
      `${URL_BASE}soporte/contrato/comentario/${id}`,
      {
        withCredentials: true,
      }
    );

    return res.data.reverse();
  } catch (error) {
    return [];
  }
}

/**
 * Toggle the status of the contract support
 * @param {number} id - Id of the contract support
 */
export async function toggleSupportContractStatus(id) {
  try {
    const res = await axios.put(`${URL_BASE}soporte/contrato/estatus/${id}`,null,{
      withCredentials:true
    });

    Swal.fire({
      title: "Actualizado",
      text: res.data.message,
      icon: "success",
    });
    return true;
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: error.data.message,
      icon: "error",
    });
    return false;
  }
}
