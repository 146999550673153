import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import ui from "./styles.module.scss";
import AddContract from "../AddContract";
import Icon from "components/individual/HeaderIcon";
import { ContextSupportContracts } from "..";
import AddComment from "../AddComment";

export default function OptionsContractSupport() {
  const hook = useContext(ContextSupportContracts);

  if (hook === undefined || hook.selectedAgreement === null) return <></>;

  return (
    <div className={ui.containerOptions}>
      <ButtonV2 onClick={hook.attemptToggleStatus}>
        <span>{hook.selectedAgreement.Estatus ? "Inactivar" : "Activar"}</span>
      </ButtonV2>

      <AddComment
        idSupportContract={hook.selectedAgreement?.ID}
        onSuccessSubmit={hook.refetchCurrentPage}
      >
        <ButtonV2>
          <span>Comentarizar</span>
        </ButtonV2>
      </AddComment>

      <AddContract idSupportContract={hook.selectedAgreement?.ID}>
        <ButtonV2>
          <span>Editar</span>
          <Icon icon="edit" />
        </ButtonV2>
      </AddContract>
    </div>
  );
}
