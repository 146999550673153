import React, { Fragment, useContext } from "react";
import { RangeDate, From, To } from "components/general/RangeDate";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { ContextMassiveInvoiceDownload } from "..";
import ui from "structure/MassiveInvoiceDownload/styles.module.scss";
import DownloadingScreen from "../DownloadingScreen";
import ListFile from "../ListFile";

export default function Step2() {
  const hook = useContext(ContextMassiveInvoiceDownload);

  return (
    <>
      <div className={ui.filter}>
        <RangeDate>
          <From
            withPortal={true}
            selected={hook.state.from}
            onChange={hook.setFrom}
          />
          <To
            withPortal={true}
            selected={hook.state.to}
            onChange={hook.setTo}
          />
          <ButtonV2
            disabled={hook.state.state === "downloading"}
            onClick={hook.handleMassiveDownload}
          >
            <span>Descargar</span>
            <Icon icon="download" />
          </ButtonV2>
        </RangeDate>
      </div>

      {hook.state.invoicesFound === 0 && (
        <p className="my-4 text-center text-danger font-weight-bold">
          No se encontraron facturas en el rango de fechas seleccionado, intenta
          otras fechas
        </p>
      )}

      <DownloadingScreen />

      {hook.state.state === "none" &&
        hook.state.overviewInvoices.length >= 1 && (
          <>
            <p className="font-weight-bold mt-3">Archivos descargados</p>

            <ol style={{ padding: 0, margin: "10px 0 0 0" }}>
              {hook.state.overviewInvoices.map((file, i) => (
                <Fragment key={`${file.folio}-factura`}>
                  <ListFile
                    folio={file.folio}
                    idPdf={file.pdf}
                    idXml={file.xml}
                    wasDownloaded={file?.isDownloaded || false}
                  />

                  {i !== hook.state.overviewInvoices.length - 1 && (
                    <hr className="m-0" />
                  )}
                </Fragment>
              ))}
            </ol>
          </>
        )}
    </>
  );
}
