import React from "react";
import Header from "./components/general/Header/Header";
import Roles from "./pages/ProcesosEspeciales/Roles/Roles";
import Avisos from "./pages/ProcesosEspeciales/Avisos/Avisos";
import Inicio from "./pages/Inicio/Inicio";
import MovementsReport from "pages/BanksV2/Movements";

// DIRECTORY
import EditCustomer from "./pages/Directory/Index/EditCustomer/EditCustomer";
import ExecutiveFilterSearchState from "context/ExecutiveFilterSearch/ExecutiveFilterSearchState";

// Bank
import Movements from "./pages/Banks/Movements/Movements";
import { AddFastMove } from "./pages/Banks/Movements/AddMovements";

import PermissionsState from "./context/Permissions/PermissionsState";
import { Route } from "react-router-dom";
import { Forecast } from "./pages/ReortesVentas/Forecast/Forecast";
import { Facturas } from "./pages/ReortesVentas/Facturas/Facturas";
import { Oportunidades } from "./pages/ReortesVentas/Contribución/Oportunidades";
import { Bancos } from "./pages/Banks/Index/Bancos";

// MOVEMENTS
import EditMovement from "./pages/Banks/Movements/Edit/EditMovement";
import Associate from "./pages/Banks/Movements/Associate";
import MovementsState from "context/Movements/MovementsState";

/////////////////////////////////////////////  DOCUMENTS
import { Component as AddDocument } from "./pages/Directory/Documents/AddDocument/Index";

////////////////////////////////////////////STATE
import UsersCheckBoxState from "./context/UsersCheckBox/UsersCheckBoxState";
import AddDocumentState from "./context/AddDocument/AddDocumentState";
import DocumentsState from "./context/Documents/DocumentsState";
import WinQuoteState from "./context/WinQuote/WinQuoteState";

//COMPONETS
import { UserProfile } from "./pages/UserMenu/UserProfile/UserProfile";
import { ViewDoc } from "./pages/Directory/Documents/ViewDoc/Index";
import { Addv2 } from "./pages/Directory/Index/Addv2/Addv2";
import AssociateFiles from "./pages/Directory/Documents/AssociateFiles/Index";
import Parameters from "pages/ProcesosEspeciales/Parameters";
import QuoteAssociatedFiles from "pages/Directory/Documents/AssociatedFiles";
import Corrections from "pages/BanksV2/Corrections/index";

import LogViewer from "./pages/Log/LogEmail";

import InvoiceReception from "pages/Administration/InvoiceReception";

import Wifi from "./components/general/Wifi/Wifi";
import EditDocument from "pages/Directory/Documents/Edit";
import AddMovementsV2 from "pages/Banks/Movements/AddMovement/AddMovementsV2";
import { UsuariosV2 } from "pages/ProcesosEspeciales/Usuarios/UsuariosV2";
import InvoiceReceptionAssociatedFiles from "pages/Administration/InvoiceReception/AssociatedFiles";
import { AssociateProviders } from "pages/Banks/Movements/CXP-Consilations/Step1/Step1";

// hI
import { EgressAssociation } from "pages/Banks/Movements/EgressAssociation";
import { AsociatedFiles } from "pages/Administration/InvoiceEmitted/AsociatedFiles";
import AssociateIngress from "pages/Banks/Movements/AssociateIngress";
import Quote from "pages/Directory/Documents/QuoteV2";
import NotesAndAclarations from "pages/ProcesosEspeciales/Parameters/NotesAndAclarations";
import EditQuote from "pages/Directory/Documents/QuoteV2/EditQuote";
import AddInvoice from "pages/Administration/AddInvoice";
import Invoice from "pages/Administration/Invoice";
import EditInvoice from "pages/Administration/EditInvoice";
import UpdateInvoice from "pages/Directory/Documents/Invoice/Update";
import AddContract from "pages/Directory/Documents/Contract/Add";
import EditContract from "pages/Directory/Documents/Contract/Edit";
import ContractList from "structure/Contract/List";
import ODC from "pages/Administration/ODC";
import AddOdc from "structure/ODC/Add";
import EditOdc from "structure/ODC/Edit";
import ToDoDocuments from "pages/Directory/Documents/ToDo";
import ToDoDirectory from "pages/Directory/Index/ToDoDirectory";
import ToDoHome from "pages/ToDo";
import ToDoContact from "pages/Directory/Contactos/ToDoContacts";

import AssociateFilesAdministration from "pages/Administration/AssociateFilesAdministration";
import { OdcAssociatedFiles } from "pages/Administration/ODC/AssociatedFiles";
import { ToDoInvoiceEmitted } from "pages/Administration/InvoiceEmitted/Todo";
import { ToDoInvoice } from "pages/Administration/Invoice/Todo";
import { ToDoOdc } from "pages/Administration/ODC/Todo";
import { Forbidden } from "pages/403";
import { AssociatedFilesMovement } from "pages/Banks/Movements/AssociatedFilesMovement";
import { Concilation } from "pages/Banks/Concilation";
import { RepresentativeExecutive } from "components/Sections/Menu/RepresentativeExecutive";
import TicketsApp from "pages/Tickets";
import { WinQuote } from "pages/Directory/Documents/WinQuoteV2/WinQuote";
import BanksV2 from "pages/BanksV2";
import Incoming from "pages/BanksV2/Incomings";
import BankState from "context/Bank/BankState";
import AddIncoming from "pages/BanksV2/Incomings/Add";
import AddEgress from "pages/BanksV2/Egresses/Add";
import useSystemInside from "customHooks/useSystemInside";
import SearchedTicket from "pages/Tickets/Search";
import PaymentAdvancesCustomers from "pages/BanksV2/PaymentAdvances/Customers";
import Authorization from "pages/ToDo/Autorization";
import PaymentAdvancesProviders from "pages/BanksV2/PaymentAdvances/Providers";
import NotasDeCreditoEmitidas from "pages/Ventas/NotasDeCreditoEmitidas";
import CreditNotesReceived from "pages/Compras/NotasDeCreditoRecibidas";
import { CreditNotesEmitedFiles } from "pages/Ventas/NotasDeCreditoEmitidas/AssociatedFiles";
import CxP from "pages/Compras/CxP";
import { FilesCreditNoteReceived } from "pages/Compras/NotasDeCreditoRecibidas/AssociatedFiles";
import OrderAuth from "pages/Ventas/OrderAuth";
import ResidueBankAccounts from "pages/BanksV2/Reports/ResidueBankAccounts";
import CashFlow from "pages/BanksV2/Reports/CashFlow";
import ConsecutivePurchaseOrder from "pages/Compras/Reports/ConsecutivePurchaseOrder";
import ContactsModule from "pages/Directory/Contactos/V2";
import UenAdmin from "pages/ProcesosEspeciales/UEN";
import CatalogueAdmin from "pages/ProcesosEspeciales/CatalogueAdmin";
import ResiduesAndMovements from "structure/ResiduesAndMovements";
import FooterPraxia from "components/general/Footer";
import HistoricalContracts from "pages/Ventas/HistoricalContracts";
import Directory from "pages/Directory";
import OldResidues from "pages/BanksV2/Reports/OldResidues";
import Cuotas from "pages/Ventas/Cuotas";
import AccountingOc from "pages/Accounting/OC";
import DocumentsV2 from "pages/Directory/DocumentsV2";
import GestionOc from "pages/Compras/GestionOc";
import BankCorrection from "context/BankCorrection/index";
import Pareto from "pages/Compras/Reports/Pareto/index";
import AuthsInvoice from "pages/Administration/AuthsInvoice";
import CreditNotesEmitedAccounting from "pages/Accounting/CreditNotesEmited";
import IvasAccounting from "pages/Accounting/Ivas";
import { Idle } from "components/general/Idle";
import PaymentReminder from "pages/Ventas/PaymentReminder";
import IVAS from "pages/Compras/IVAS";
import Test from "pages/Test";
import Proyects from "pages/Proyects";
import ProyectAdd from "pages/Proyects/Add";
import AddPosition from "pages/Proyects/Posicion";
import Materials from "pages/Materials";
import Reception from "pages/Proyects/Reception";
import OcProyects from "pages/Proyects/OcProyects";
import Remition from "pages/Proyects/Remition";
import AssociatedFilesRemition from "pages/Proyects/Remition/AssociatedFilesRemition";
import Propousal from "pages/Proyects/Propousal";
import FeCorreccion from "./pages/FeCorreccion";
import AccountingCancelated from "pages/Accounting/Cancelated";
import SupportContracts from "pages/Support/Contracts";
import ContractAssociatedFiles from "structure/Contract/AssociatedFiles";

export default function SystemInside() {
  useSystemInside();

  return (
    <BankCorrection>
      <Idle />

      <div className="containerApp">
        <Wifi />

        <PermissionsState>
          <Header />

          <main>
            <Route exact path="/avisos" component={Avisos} />

            <Route exact path="/soporte/contratos" component={SupportContracts} />

            <Route exact path="/test" component={Test} />

            <Route exact path="/logs" component={LogViewer} />
            <Route exact path="/fe/correccion" component={FeCorreccion} />

            <Route exact path="/proyectos" component={Proyects} />
            <Route
              exact
              path="/proyectos/formulario/:id"
              component={ProyectAdd}
            />
            <Route
              exact
              path="/proyectos/posicion/agregar/:id"
              component={AddPosition}
            />
            <Route
              exact
              path="/proyectos/materiales/:idPosition"
              component={Materials}
            />
            <Route
              exact
              path="/proyectos/recepcion/:idOc"
              component={Reception}
            />
            <Route
              exact
              path="/proyectos/oc/:idProyect"
              component={OcProyects}
            />
            <Route
              exact
              path="/proyectos/remision/:idPosition"
              component={Remition}
            />
            <Route
              exact
              path="/proyectos/remision/archivos-asociados/:idPreinvoice"
              component={AssociatedFilesRemition}
            />
            <Route
              exact
              path="/proyectos/propuesta/:idProyect"
              component={Propousal}
            />

            <BankState>
              <Route
                exact
                path="/documentos/cotizacion/agregar"
                component={Quote}
              />

              <Route
                exact
                path="/documentos/cotizacion/editar"
                component={EditQuote}
              />

              {/* TOOLS / HELP DESK / TICKETS*/}
              <Route
                exact
                path="/procesos-especiales/help-desk/tickets"
                component={TicketsApp}
              />

              <Route exact path="/ivas" component={IVAS} />

              <Route
                exact
                path="/procesos-especiales/help-desk/tickets/busqueda"
                component={SearchedTicket}
              />

              <Route
                exact
                path="/documentos/contrato/editar"
                component={EditContract}
              />

              <Route exact path="/to-do" component={ToDoHome} />

              <Route exact path="/403" component={Forbidden} />

              <Route
                exact
                path="/administracion/pedido/agregar"
                component={AddInvoice}
              />

              <Route
                exact
                path="/administracion/pedido/editar"
                component={EditInvoice}
              />

              <Route
                exact
                path="/documentos/pedido/editar"
                component={UpdateInvoice}
              />

              {/* <ContractState> */}
              <Route
                exact
                path="/documentos/contrato/agregar"
                component={AddContract}
              />

              <Route
                exact
                path="/administracion/ordenes-compra"
                component={ODC}
              />

              <Route exact path="/uen" component={UenAdmin} />
              <Route exact path="/catalogo" component={CatalogueAdmin} />

              <Route
                exact
                path="/administracion/consecutivo-oc"
                component={ConsecutivePurchaseOrder}
              />

              <Route
                exact
                path="/administracion/ordenes-compra/archivos-asociados/:idDocument"
                component={OdcAssociatedFiles}
              />

            <Route
                exact
                path="/ventas/contratos/archivos-asociados/:id"
                component={ContractAssociatedFiles}
              />

              <Route
                exact
                path="/ventas/notas-de-credito/archivos-asociados/:idDocument"
                component={CreditNotesEmitedFiles}
              />

              <Route exact path="/ventas/cuotas" component={Cuotas} />

              <Route
                exact
                path="/compras/notas-de-credito/archivos-asociados/:idDocument"
                component={FilesCreditNoteReceived}
              />

              <Route
                exact
                path="/contabilidad/notas-de-credito/emitidas"
                component={CreditNotesEmitedAccounting}
              />

              <Route
                exact
                path="/contabilidad/facturas-canceladas"
                component={AccountingCancelated}
              />

              <Route exact path="/contabilidad/notas-de-credito/recibidas">
                <CreditNotesReceived module="contabilidad" />
              </Route>

              <Route exact path="/compras/gestion-oc" component={GestionOc} />

              <Route exact path="/documentos/odc/agregar" component={AddOdc} />

              <Route exact path="/documentos/odc/editar" component={EditOdc} />

              <Route exact path="/ventas/contratos" component={ContractList} />

              <Route
                exact
                path="/ventas/notas-de-credito"
                component={NotasDeCreditoEmitidas}
              />

              <Route exact path="/compras/cxp" component={CxP} />

              <Route exact path="/compras/pareto" component={Pareto} />

              <Route exact path="/ventas/cxc">
                <CxP type="cxc" />
              </Route>

              <Route
                exact
                path="/compras/notas-de-credito"
                component={CreditNotesReceived}
              />

              <Route exact path="/pedido/timbrar" component={OrderAuth} />

              <Route
                exact
                path="/ventas/contratos/agregar"
                component={AddContract}
              />

              {/* </ContractState> */}

              <Route exact path="/administracion/pedidos" component={Invoice} />

              <Route exact path="/inicio" component={Inicio} />

              <Route exact path="/log" component={LogViewer} />

              <Route exact path="/autorizacion" component={Authorization} />

              <UsersCheckBoxState>
                <Route exact path="/roles" component={Roles} />
              </UsersCheckBoxState>

              {/* <Route exact path="/usuarios" component={Usuarios} /> */}
              <Route exact path="/usuarios" component={UsuariosV2} />

              <Route
                exact
                path="/procesos-especiales/parametros/notas-y-aclaraciones"
                component={NotesAndAclarations}
              />

              <Route
                exact
                path="/directorio/:idCustomer/documentos/:nameDocument/archivos-asociados/:idDocument"
                component={QuoteAssociatedFiles}
              />

              <Route
                exact
                path="/administracion/archivos-asociados/:nameDocument/:idDocument"
                component={AssociateFilesAdministration}
              />

              <Route
                exact
                path="/administracion/recepcion-facturas"
                component={InvoiceReception}
              />

              <Route exact path="/contabilidad/facturas-recibidas">
                <InvoiceReception isForAccounting={true} />
              </Route>

              <Route exact path="/contabilidad/ivas">
                <IvasAccounting />
              </Route>

              <Route exact path="/contabilidad/oc">
                <AccountingOc />
              </Route>

              <Route
                exact
                path="/saldos-y-movimientos"
                component={ResiduesAndMovements}
              />

              <Route exact path="/movimientos" component={MovementsReport} />

              <Route exact path="/compras/saldos-y-movimientos">
                <ResiduesAndMovements type="compras" />
              </Route>

              <Route exact path="/administracion/facturas-emitidas">
                <Invoice from="facturas_emitidas" />
              </Route>

              <Route exact path="/contabilidad/facturas-emitidas">
                <Invoice from="contabilidad" renderAccounting={true} />
              </Route>

              <Route
                exact
                path="/administracion/facturas-emitidas/todo"
                component={ToDoInvoiceEmitted}
              />

              <Route
                exact
                path="/administracion/pedidos/todo"
                component={ToDoInvoice}
              />

              <Route
                exact
                path="/administracion/ordenes-compra/todo"
                component={ToDoOdc}
              />

              <Route
                exact
                path="/ventas/cobranza"
                component={PaymentReminder}
              />

              <Route
                exact
                path="/administracion/facturas-emitidas/archivos/:idInvoice"
                component={AsociatedFiles}
              />

              <Route
                exact
                path="/administracion/recepcion-facturas/archivos/:idInvoice"
                component={InvoiceReceptionAssociatedFiles}
              />

              <Route
                exact
                path="/procesos-especiales/parametros"
                component={Parameters}
              />

              <Route
                exact
                path="/ventas/historico-contratos"
                component={HistoricalContracts}
              />

              <ExecutiveFilterSearchState>
                {/* DIRECTORY */}

                <Route exact path="/directorio" component={Directory} />

                <Route
                  exact
                  path="/directorio/to-do"
                  component={ToDoDirectory}
                />

                <Route
                  exact
                  path="/directorio/contactos/to-do"
                  component={ToDoContact}
                />

                <Route exact path="/directorio/agregar" component={Addv2} />
                <Route
                  exact
                  path="/directorio/editar/:customerId"
                  component={EditCustomer}
                />
                <Route
                  exact
                  path="/directorio/contacto/:customerId"
                  component={ContactsModule}
                />

                {/* /////////////////////////////////// DOCUMENTS ////////////////////// */}

                <Route
                  exact
                  path="/directorio/documentos/:idCustomer/documento/:idDocument/archivos/ver"
                >
                  <AssociateFiles />
                </Route>

                {/* ADD DOCUMENT */}

                <Route
                  exact
                  path="/directorio/documentos/:customerId/:typeDocument/editar/documento/:documentId"
                >
                  <EditDocument />
                </Route>

                <AddDocumentState>
                  <Route
                    exact
                    path="/directorio/documentos/:customerId/:typeDocument/:action/:documentId"
                    component={AddDocument.Index}
                  />
                  <WinQuoteState>
                    <DocumentsState>
                      <Route
                        exact
                        path="/directorio/documentos/:customer"
                        component={DocumentsV2}
                      />
                    </DocumentsState>

                    <Route
                      exact
                      path="/directorio/documentos/:customerId/ganar2/:idQuote"
                      component={WinQuote}
                    />
                  </WinQuoteState>
                </AddDocumentState>
              </ExecutiveFilterSearchState>

              <Route path="/directorio/documentos/:idCustomer/ver/:idDocument">
                <ViewDoc />
              </Route>

              {/* Reminders */}
              <Route
                exact
                path="/directorio/documentos/reminders/:customerId/:idDocument"
                component={ToDoDocuments}
              />

              <Route exact path="/directorio" />

              <Route exact path="/autorizaciones" component={AuthsInvoice} />

              {/* ///////////////////////////////////////// BANKS ///////////////////////// */}

              {/* MOVEMENTS */}

              <Route
                exact
                path="/bancos/movimientos/:account/asociar-ingreso/:idMovement"
              >
                <AssociateIngress />
              </Route>

              <MovementsState>
                <Route
                  exact
                  path="/bancos/movimientos/:account"
                  component={Movements}
                />
                <Route
                  exact
                  path="/bancos/movimientos/:account/agregar/:typeMovement"
                  component={AddFastMove}
                />
                <Route
                  exact
                  path="/bancos/movimientos/:account/ingreso-rapido"
                  component={AddMovementsV2}
                />

                <Route exact path="/bancos/movimientos/:account/egreso-rapido">
                  <AddMovementsV2 typeMovement={0} />
                </Route>

                <Route
                  exact
                  path="/bancos/movimientos/:account/editar/:movement"
                  component={EditMovement}
                />

                <Route
                  exact
                  path="/bancos/movimientos/:account/asociar/:movement"
                  component={Associate}
                />
                <Route
                  exact
                  path="/bancos/movimientos/:account/asociar/egresos/:movement"
                  component={AssociateProviders}
                />

                <Route
                  exact
                  path="/bancos/movimientos/:account/asociar-gasto/:movement"
                  component={EgressAssociation}
                />
              </MovementsState>

              <Route exact path="/forecast" component={Forecast} />
              <Route exact path="/facturas" component={Facturas} />
              <Route exact path="/oportunidades" component={Oportunidades} />

              <Route
                exact
                path="/bancos/movimientos/:idBankAccount/archivos-asociados"
                component={AssociatedFilesMovement}
              />
              <Route exact path="/v2/bancos" component={BanksV2} />

              <Route exact path="/contabilidad/ingresos">
                <Incoming type="ingreso" isForAccounting={true} />
              </Route>

              <Route exact path="/contabilidad/egresos">
                <Incoming type="egreso" isForAccounting={true} />
              </Route>

              <Route exact path="/v2/bancos/ingresos" component={Incoming} />

              <Route
                exact
                path="/v2/bancos/conciliacion"
                component={Concilation}
              />

              <Route
                exact
                path="/v2/bancos/correccion"
                component={Corrections}
              />

              <Route
                exact
                path="/v2/bancos/ingresos/agregar"
                component={AddIncoming}
              />

              <Route exact path="/v2/bancos/proyeccion-flujo-efectivo">
                <CashFlow type="proyeccion" />
              </Route>

              <Route exact path="/v2/bancos/flujo-efectivo">
                <CashFlow type="flujo" />
              </Route>

              <Route exact path="/v2/bancos/egresos">
                <Incoming type="egreso" />
              </Route>

              <Route exact path="/v2/bancos/cxc/antiguedad-saldos">
                <OldResidues />
              </Route>

              <Route exact path="/v2/bancos/cxp/antiguedad-saldos">
                <OldResidues type="cxp" />
              </Route>

              {/* <Route exact path="/v2/bancos/egresos" component={Egresses} /> */}

              <Route
                exact
                path="/v2/bancos/egresos/agregar"
                component={AddEgress}
              />
            </BankState>

            <Route
              exact
              path="/v2/bancos/anticipos/clientes"
              component={PaymentAdvancesCustomers}
            />

            <Route
              exact
              path="/v2/bancos/anticipos/proveedores"
              component={PaymentAdvancesProviders}
            />

            <Route
              exact
              path="/v2/bancos/saldos"
              component={ResidueBankAccounts}
            />

            <Route exact path="/bancos" component={Bancos} />
            <Route exact path="/userprofile" component={UserProfile} />
            <Route
              exact
              path="/representante"
              component={RepresentativeExecutive}
            />
          </main>
        </PermissionsState>

        <FooterPraxia />
      </div>
    </BankCorrection>
  );
}
