import LoginContext from "context/Login/LoginContext";
import { downloadPdfDocumentV3 } from "helpers/Apis/Documents";
import React, { useContext } from "react";
import { ContextContract } from "..";
// import { TerminateContract } from "../TerminateContract";
import { DonwloadPdfV2 } from "components/general/Documents/DownloadPdf/DonwloadPdfV2";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import TerminateContract from "structure/TerminateContract";
import EditDocumentButton from "components/individual/buttons/EditButtonDocument";
import RedirectBtn from "components/individual/RedirectBtn";

export default function Options({ idCustomer }) {
  const { contract, viewDocument, isOperable, editDocument, triggerSearch } =
    useContext(ContextContract);

  const { userInfo } = useContext(LoginContext);

  return (
    <div className={ui.options}>
      {contract !== null ? (
        <>
          <ButtonV2 onClick={viewDocument}>
            <span>Ver documento</span>
          </ButtonV2>

          <RedirectBtn link={`/ventas/contratos/archivos-asociados/${contract.id}`}>
            <span>Archivos asociados</span>
          </RedirectBtn>

          {isOperable ? (
            // <ButtonV2 onClick={editDocument}>Editar123</ButtonV2>
            <EditDocumentButton
              goEdit={editDocument}
              idDocument={contract.id}
            />
          ) : null}

          <DonwloadPdfV2
            id={contract.id}
            fileName={`Contrato - ${contract.numeroDocumento}`}
          />

          <EmailDocumentsV2
            idDocument={contract.id}
            subject={`Praxia - Contrato ${contract.numeroDocumento}`}
            message={`Se le adjunta la informacion del contrato ${contract.numeroDocumento} (pdf)`}
            cc={[userInfo[0].email]}
            to={[contract.contact.email]}
            files={async () =>
              downloadPdfDocumentV3(
                contract.id,
                `Contrato-${contract.numeroDocumento}`
              )
            }
          >
            <ButtonV2>
              <span>Enviar correo</span>
            </ButtonV2>
          </EmailDocumentsV2>

          <TerminateContract
            idContract={contract.id}
            idStatus={contract.estatusId}
            onCancelled={triggerSearch}
          >
            <ButtonV2>
              <span>Terminar</span>
            </ButtonV2>
          </TerminateContract>
        </>
      ) : null}
    </div>
  );
}
