import { Spinner } from "components/individual/Spinner/Spinner";
import { getFullDirectory } from "helpers/Apis/Directory";
import React, { useEffect, useState } from "react";
import Select from "react-select";

/**
 * @type {import("./types").StateCustomerCombo}
 */
const INITIAL_STATE = {
  customers: [],
  isLoading: true,
  defaultValue: null,
};

/**
 * Render a combo with all the customers of the system
 * @param {import("./types").PropsCustomerCombo} props - Props
 * @returns {JSX.Element}
 */
export default function Customer({
  type = "cliente",
  onChange = () => {},
  defaultValue = null,
  onLoaded = () => {},
  props ={}
}) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const apiDirectory = await getFullDirectory(type);
      setState((current) => ({
        ...current,
        customers: apiDirectory.map((customer) => ({
          ...customer,
          value: customer.id,
          label: customer.socialReason,
        })),
      }));
    })();
  }, []);

  useEffect(() => {
    if (state.customers.length <= 0) return;

    const item = state.customers.findIndex(
      (customer, i) => customer.id === defaultValue
    );

    setState((current) => ({
      ...current,
      isLoading: false,
      defaultValue: item,
    }));
  }, [defaultValue, state.customers]);

  useEffect(()=>{
    onLoaded()
  },[state.customers])

  if (state.customers.length <= 0 || state.isLoading)
    return <Spinner text={"Cargando"} hidden={false} />;

  return (
    <Select
      classNamePrefix="saiko-select"
      className="saiko-select"
      placeholder={`Selecciona`}
      isLoading={state.isLoading}
      menuPortalTarget={window.document.body} 
      defaultValue={state.customers[state.defaultValue]}
      isClearable={true}
      isSearchable={true}
      options={state.customers}
      onChange={(customer) =>
        onChange(customer?.id || null, customer?.label || "")
      }
      {...props}
    />
  );
}
