import { Spinner } from "components/individual/Spinner/Spinner";
import { getTags } from "helpers/Apis/support";
import React, { useEffect, useState } from "react";
import Select from "react-select/creatable";
import { Label } from "structure/Document";

/**
 * Handle tag of the support module for `Alvaro`
 * @param {import("./types").PropsTagsSupport} props
 */
export default function TagsSupport({
  onChange = () => {},
  value = null,
  type = "contratos",
}) {
  const [state, setState] = useState({
    tags: [],
    status: "loading",
  });

  useEffect(() => {
    (async function () {
      const tags = await getTags(type);

      setState((current) => ({
        ...current,
        tags: tags.map((tag) => ({ value: tag.id, label: tag.description })),
        status: "none",
      }));
    })();
  }, []);

  if (state.status === "loading")
    return <Spinner hidden={false} text={"Cargando tags"} />;

  return (
    <div>
      <Label>{type === "contratos" ? "Tipo de convenio" : "Asunto"}</Label>
      <Select
        classNamePrefix="saiko-select"
        className="saiko-select"
        placeholder="Selecciona o escribe aquí"
        options={state.tags}
        onChange={(tag) => onChange(tag)}
        defaultValue={state.tags.find((tag) => tag.value === value)}
      />
    </div>
  );
}
