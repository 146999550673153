import useDialog from "customHooks/useDialog";
import React, { useEffect, useState } from "react";
import Dialog from "structure/Dialog";
import ui from "./styles.module.scss";
import FormContract, {
  Hours,
  Tag,
  CustomerField,
  Submit,
  CustomerFieldRead,
  Expiration,
} from "../FormContract";

/**
 * Render a modal to manipulate the form of a support contract
 * @param {import("./types").PropsAddContract} props - Props
 */
export default function AddContract({
  children,
  onSuccess = () => {},
  idSupportContract = null,
}) {
  const [state, setState] = useState({
    isOpen: false,
  });

  const dialog = useDialog();

  useEffect(() => {
    if (state.isOpen) dialog.open();
  }, [state.isOpen]);

  const open = () => setState((current) => ({ ...current, isOpen: true }));
  const close = () => setState((current) => ({ ...current, isOpen: false }));

  return (
    <>
      <div onClick={open}>{children}</div>

      {state.isOpen && (
        <FormContract
          idSupportContract={idSupportContract}
          onSuccessSubmited={() => {
            close();
            onSuccess();
          }}
        >
          <Dialog
            title={
              idSupportContract === null
                ? "Agregar contrato"
                : "Editar contrato"
            }
            forwardRef={dialog.refDialog}
            closeCallback={close}
            width={30}
            footer={
              <div className="w-100">
                <Submit />
              </div>
            }
          >
            <div className={ui.formContractSupport}>
              {idSupportContract === null ? (
                <>
                  <CustomerField />
                  <Hours />
                  <Expiration/>
                  <Tag />
                </>
              ) : (
                <>
                  <CustomerFieldRead />
                  <Hours />
                  <Expiration/>
                  <Tag />
                </>
              )}
            </div>
          </Dialog>
        </FormContract>
      )}
    </>
  );
}
